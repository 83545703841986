<template>
  <!-- 开票申请 -->
  <div :class="themeClass">
    <div v-loading="loading">
      <el-table :data="tableData" border style="width: 100%;">
        <el-table-column prop="fBillNumber" fixed label="申请单号" width="120" align="center">
        </el-table-column>
        <!-- <el-table-column prop="fApproveStatusName" fixed label="审核状态" width="60" align="center"> </el-table-column> -->
        <el-table-column prop="fStatusName" label="开票状态" width="70" align="center"> </el-table-column>
        <!-- <el-table-column prop="fApplyID" label="申请单号" width="140" align="left"> </el-table-column> -->
        <!-- <el-table-column prop="fShopName" label="店铺名称" width="180" align="left"> </el-table-column> -->
        <el-table-column prop="fYearPeriod" label="年月" width="80" align="center"> </el-table-column>
        <el-table-column prop="fCreateTime" label="申请时间" width="135" align="center"> </el-table-column>
        <!-- <el-table-column prop="fCustomerName" label="客户" width="220" align="left"> </el-table-column> -->
        <el-table-column prop="fSumAmount" label="总数量" width="100" align="right"> </el-table-column>
        <el-table-column prop="fSumMoney" label="总不含税金额(元)" width="160" align="right"> </el-table-column>
        <el-table-column prop="fRemark" label="备注" width="auto" align="left"> </el-table-column>
        <el-table-column label="操作" width="90" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.fApproveStatus==1 || scope.row.fApproveStatus==255 " @click="returnbutton(scope.row)" size="small">撤回</el-button>
            <el-button type="text" size="small" @click="openclickDetail(scope.row)">明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" :currentPage="page" :pagesize="maxResultCount" @handleCurrentChange="handleCurrentChange" />
      <Detail :open='openDetail' :rowId='DetailFbiid' v-if="openDetail" @close='openDetail=false' />
    </div>
  </div>
</template>
<script>
import {
  mapGetters
} from "vuex";
import pagination from '@/components/pagination/pagination';
import Detail from './Detail.vue'
export default {
  components: {
    pagination,
    Detail
  },
  data () {
    return {
      openDetail: false,
      loading: false,
      total: 0, // 条数
      page: 1, // 页数
      maxResultCount: 20,
      total1: 0, // 条数
      page1: 1, // 页数
      pdfClick: true, //导出pdf直到接口返回成功或者失败才能再次点击
      excelClick: true, //导出excel直到接口返回成功或者失败才能再次点击
      shopList: '',
      tableData: [],
      checkData: [],
      DetailFbiid: '',
    };
  },
  created () {

  },
  computed: {
    ...mapGetters(["getThemeName"]),
    themeClass () {
      return `theme-${this.getThemeName}`;
    },
  },
  mounted () {
    this.getTable()
  },
  methods: {
    returnbutton(val){
      this.$confirm('是否确认撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        callback: () => {},
        beforeClose: (action, ctx, close) => {
          if (action !== 'confirm') {
            close();
            return;
          }
            // confirmButtonLoading 是在 elementUI-message-box下的 main.vue 文件中封装的参数
          ctx.confirmButtonLoading = true;
						// let a
						this.ApiRequestPost('/api/mall/ebbalance/brief-apply-bill/delete', 
            {
              fApplyID:val.fApplyID,frv:val.frv,rowStatus:val.rowStatus
            }).then(
							result => {
                this.$emit('close')
                ctx.confirmButtonLoading = false
                close();

							},
							error => {
                this.$emit('close')
                ctx.confirmButtonLoading = false
							}
						)
        }
      }).finally(() => {ctx.confirmButtonLoading = false;this.getTableData();})
    },
    openclickDetail (val) {
      this.DetailFbiid = val.fApplyID
      console.log(this.DetailFbiid)
      this.openDetail = true
    },
    handleCurrentChange(val) {
      this.page = val
      this.getTable()
    },
    NumFormat (value) {
      if (!value) return '0.00'
      value = Number(value)
        .toFixed(2)
        .toString()
      var intPart = parseInt(Number(value)) //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
      var floatPart = '.00' //预定义小数部分
      var value2Array = value.split('.')
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString() //拿到小数部分
        if (floatPart.length == 1) {
          //补0,实际上用不着
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    },
    getTable (val) {
      if (val != undefined) {
        console.log(val)
        this.loading = true
        this.ApiRequestPostNOMess("/api/mall/ebbalance/brief-apply-bill/get-list", {
          "fShopUnitID": val.fShopUnitID,
          "fYearPeriod": val.fYearPeriod,
          maxResultCount: this.maxResultCount,
          skipCount: (this.page - 1) * this.maxResultCount,
        }).then(
          (res) => {
            console.log(res)
            this.loading = false
            this.tableData = res.obj.items
            this.tableData.forEach(item => {
              item.fSumAmount = this.NumFormat(item.fSumAmount)
              item.fSumMoney = this.NumFormat(item.fSumMoney)
              item.fCreateTime = this.getTime(item.fCreateTime)
            })
          },
          (error) => {
          }
        );
      }
    },


    handleSelectionChange (val) {
      this.checkData = val;
      console.log(this.checkData)
    },
    // 全选
    handleSelectionAll (val) {
      this.checkData = val
      console.log(this.checkData)
    },
  },

};
</script>
<style scoped lang="scss">
.margb {
  margin-bottom: 20px;
}

/deep/ .el-input__suffix-inner {
  // line-height: 50px;
  // display: none;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__item {
  padding: 0;
  width: 75px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

/deep/ .el-tabs__active-bar {
  display: none;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.inputBor {
  display: flex;
  border: 1px solid #f2f2f2;

  /deep/.el-input__inner {
    border: 0 !important;
  }

  span {
    line-height: 30px;
  }
}

/deep/ .el-input__icon {
  line-height: 22px;
}

/deep/ .el-tabs__item.is-active {
  color: #fff;

  @include themify($themes) {
    background-color: themed("themes_color");
  }
}

.top {
  width: 100%;
  height: 32px;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 240px;
  }

  .search-box {
    width: 270px;
  }

  .search-btn {
    width: 79px;
    height: 32px;
    line-height: 32px;
    background: #f8f7fa;
    border-radius: 4px;
    border: 1px solid #f2f2f2;
  }
}

/deep/ .el-input {
  height: 32px;
  line-height: 32px;
}

/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}

/deep/ .el-date-editor .el-range__icon {
  height: 32px;
}

/deep/ .el-date-editor .el-range-separator {
  height: 32px;
}

/deep/ .el-input__suffix-inner {
  // line-height: 50px;
}
.tabone-num {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: #6c40bf;
  text-align: center;
}

.tabone-con {
  font-size: 14px;
  color: #6c40bf;
  border-bottom: none;
}

.tabone-num1 {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: #333333;
}

.tabone-con1 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-bottom: none;
}

.date {
  position: relative;
  top: 2px;
  left: 285px;
}

.myPointsContent {
  width: 917px;
  min-height: 485px;
  background-color: #ffffff;
  // padding:0px 13px;
}

.allOrder-btn {
  display: flex;
  // position: relative;
  // left: 295px;
}

.allOrder-btn div:nth-child(1) {
  width: 75px;
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  line-height: 32px;
}

.allOrder-btn div:nth-child(2) {
  width: 110px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  margin-right: 10px;
}

.allOrder-btn div:nth-child(3) {
  width: 110px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  margin-right: 10px;
}

.allOrder-btn div:nth-child(4) {
  width: 110px;
  height: 32px;
  text-align: center;
  line-height: 32px;
}

.block {
  margin-left: 260px;
  margin-right: 10px;
}

::v-deep .el-table__row {
  height: 38px;
}

.red {
  color: #ff0000;
}

.black {
  color: #000;
}

/* tab切换标题 */
.tabTitleRow {
  height: 40px;
  background-color: #f8f9fe;
  border: 1px solid #eee;
  margin-top: 17px;
  box-sizing: border-box;
  margin-bottom: 17px;
}

::v-deep .el-table .cell {
  white-space: nowrap;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

::v-deep .el-table thead {
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  text-align: center;
}

::v-deep .el-table th {
  height: 38px;
  padding: 0px 0px;
  text-align: center;
  color: #666666;
  font-weight: normal;
}
::v-deep .el-table td,
::v-deep .el-table th {
  height: 38px;
  padding: 0px 0px;
  color: #666666;
  font-weight: normal;
  font-size: 12px;
}

::v-deep .el-table th {
  text-align: center;
}
::v-deep .is-leaf {
  border-right: 0px solid transparent;
  background-color: #f2f2f2;
}

.tabTitleRow div {
  width: 108px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: #666;
  text-align: center;
  box-sizing: border-box;
}

.tabTitleRow div:first-child {
  border-left: none !important;
}

.tabTitleRow .checkTitleSty {
  @include themify($themes) {
    color: themed("themes_color");
  }

  font-weight: bold;
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.line-height39 {
  line-height: 39px;
}

.billtext {
  line-height: 36px;
}

.color-theme {
  @include themify($themes) {
    color: themed("themes_color");
  }
}

.background-color-theme {
  @include themify($themes) {
    background-color: themed("themes_color");
    color: themed("text_color_white");
  }
}

.aaa {
  width: 800px;
  justify-content: space-evenly;
  margin-top: 20px;
  border-bottom: none;
}

.tabOne {
  width: 100%;
  height: 135px;
  background: #f8f5fc;
  border-radius: 2px;
  border: 1px solid #6c40bf;
  margin-top: 10px;
  position: relative;
}

.tabOne-Title {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  color: #6c40bf;
  margin: 10px 0px 10px 10px;
}

.tabOne img {
  width: 180px;
  height: 119px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.topbgc {
  // width: 917px;
  height: 35px;
  background: #f8f9fe;
  padding: 0 15px;
  margin: 11px auto;
}

::v-deep.el-input--suffix .el-input__inner {
  line-height: 32px !important;
  height: 32px !important;
}

::v-deep .el-input__icon {
  line-height: 32px !important;
}

.billsitems {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #f2f2f2;
}

.allOrder-btn .zad {
  /* position: absolute;
		left: 1095px; */
}

.allOrder-btn .ad {
  /* position: absolute;
		left: 1175px; */
}
// /deep/ .el-input {
//   width: 185px !important;
// }
/deep/ .el-pagination {
  padding: 3px 5px;
}
</style>
