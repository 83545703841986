<!--货款发票 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20 myPointsContent">
			<div class="title font-size18 font-weight700 font-color-333" v-if="!openshowInvoicing">
				货款发票
			</div>
			<div class="flex-row-align-flex-end margb" style="margin-top: 10px;" v-if="!openshowInvoicing">
				<div class=" margin-r-10 " style="display: flex;align-items: center;">
					<div style="color: #666666;margin-right: 10px;min-width:31px">店铺</div>
					<el-select v-model="shopList" placeholder="请选择店铺" ref="fBankTypeName">
						<el-option v-for="(item,index) in shopArr" :key="index" :label="item.FCompanyName"
							:value="item.FCompanyID "></el-option>
					</el-select>
				</div>
				<div class="" style="display: flex;align-items: center;">
					<div style="color: #666666;margin-right: 10px;min-width:31px">年月</div>
					<el-date-picker v-model="value" type="month" placeholder="选择月" value-format="yyyy-MM" :clearable='false' :editable='false'  :picker-options="pickerOptions">
					</el-date-picker>

				</div>
				<div class="allOrder-btn">
					<div type="primary" class="background-color-theme zad pointer" @click="searchqingdan">查询</div>
					<div type="primary" class="background-color-theme zad pointer" @click="downexcl">Execl表格导出</div>
					<div type="primary" class="background-color-theme zad pointer" @click="downpdf">PDF导出</div>
					<div v-if="listShow == 0" type="primary" class="background-color-theme zad pointer" @click="Sign">
						发票签收</div>
          <div v-if="listShow == 2" type="primary" class="background-color-theme zad pointer" @click="openInvoicing">
						开票申请</div>  
				</div>
			</div>
			<!-- tab切换 -->
			<div v-if="!openshowInvoicing" class="tabTitleRow pointer">
				<div v-for="(item,index) in tabData" :key="index" :class="item.checked?'checkTitleSty':''"
					@click="selecttab(index)">
					{{item.title}}
				</div>
			</div>
			<!-- 统计报表   tableData1  -->
			<div v-if="listShow == 0">
				<div class="tabTwo hide-text">
					<el-table :data="tableData1" ref="multipleTable" border style="width: 100%;"
						@selection-change="handleSelectionChange" @select-all="handleSelectionAll">
						<el-table-column type="selection" fixed width="27" align="center" />
            <el-table-column  label="操作" width="100" fixed align="center">
							<template slot-scope="scope">
                <el-button @click.stop="showImgClick(scope.row.fPdfUrl)" type="text"
                size="small">{{scope.row.fPdfUrl != ''   ? '下载' :''}}</el-button>
							</template>
						</el-table-column>
						<el-table-column  align="left" prop="fCompanyName" label="店铺" width="101">
							<template slot-scope="scope">
								<el-tooltip :content="scope.row.fCompanyName" placement="top">
									<span style="cursor: pointer;">{{scope.row.fCompanyName}}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column prop="fSourceBillNumber" label="发票号" width="159" align="center"> </el-table-column>
						<el-table-column prop="fSumAmount" label="商品总数量" width="78" align="right"> </el-table-column>
						<el-table-column prop="fSumMoneyTax" label="总含税金额(元)" width="95" align="right"> </el-table-column>
						<el-table-column prop="fSumMoney" label="总不含税金额(元)" width="105" align="right"> </el-table-column>
						<!-- <el-table-column prop="fPostName" label="快递公司" width="180">
						</el-table-column> -->
						<el-table-column label="快递单号" prop="fPostName" width="100" align="left">
							<template slot-scope="scope">
								<el-tooltip :content="scope.row.fPostNumber" placement="top">
								<span @click="gokuaidilink(scope.row)"
									style="cursor: pointer;color: #0173FE;">{{scope.row.fPostNumber}}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column prop="fPostName" label="快递公司" width="62" align="left"></el-table-column>
						<el-table-column prop="fKeepDate" label="开票日期" width="80" align="center"> </el-table-column>
						<el-table-column prop="fSigninStatusName" label="签收状态" width="60" align="center"> </el-table-column>
						<el-table-column prop="fSigniner" label="签收人" width="50" align="left"> </el-table-column>
						<el-table-column prop="fSigninTime" label="签收时间" width="90" align="center">
							<template slot-scope="scope">
								<el-tooltip :content="scope.row.fSigninTime" placement="top">
									<span style="cursor: pointer;">{{scope.row.fSigninTime}}</span>
								</el-tooltip>
							</template>
						</el-table-column>

					</el-table>
					<pagination :total="total1" :currentPage="page1" :pagesize="maxResultCount1"
						@handleCurrentChange="select" />
				</div>
			</div>

			<!-- 发票明细   tableData-->
			<div v-if="listShow == 1">
				<div>
					<el-table :data="tableData" border style="width: 100%;">
						<el-table-column prop="fDepotName" fixed label="仓库" width="140" align="left"> </el-table-column>
						<el-table-column prop="fGoodsFullName" fixed label="商品名称" width="180" align="left"> </el-table-column>
						<el-table-column prop="fOrderNumber" label="订单号" width="130" align="left"> </el-table-column>
						<el-table-column prop="fDeliveryOrderNumber" label="出库单号" width="130" align="left"> </el-table-column>
						<el-table-column prop="fKeepDate" label="出库日期" width="140" align="center"> </el-table-column>
						<el-table-column prop="fPlateNumber" label="车号" width="100" align="left"> </el-table-column>
						<el-table-column prop="fDeliveryAmount" label="出库量(吨)" width="100" align="right"> </el-table-column>
						<el-table-column prop="fGoodsPrice" label="出库价(元)" width="120" align="right"> </el-table-column>
						<el-table-column prop="fMoneyTax" label="金额(元)" width="130" align="right"> </el-table-column>
					</el-table>
					<pagination :total="total" :currentPage="page" :pagesize="maxResultCount"
						@handleCurrentChange="select" />
				</div>
			</div>
      <!-- 开票申请 -->
      <div v-if="listShow == 2">
        <customertable v-if="!openshowInvoicing" ref="customertable" @close='selecttab(2)'/>
      </div>
      <!-- 申请开票 -->
      <div v-if="openshowInvoicing">
        <Invoicing @close='openshowInvoicing = false;selecttab(2)'/>
      </div>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from '@/components/pagination/pagination';  
  import customertable from './customertable.vue' //开票申请
  import Invoicing from './Invoicing.vue' //申请开票
	export default {
		components: {
			pagination,
      customertable,
      Invoicing
		},
		data() {
			return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
        openshowInvoicing: false,
				total: 0, // 条数
				page: 1, // 页数
				maxResultCount: 20,
				total1: 0, // 条数
				page1: 1, // 页数
				maxResultCount1: 20,
				pdfClick: true, //导出pdf直到接口返回成功或者失败才能再次点击
				excelClick: true, //导出excel直到接口返回成功或者失败才能再次点击
				shopList: '',
				shopArr: [{
					shopneme: '5'
				}],
				activeName: "sevendays",
				searchDate: [new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7)), new Date()],
				value: "",
				listShow: 0,
				defaultTime: '', //时间默认值
				tableData: [],
				tableData1: [],
				tabData: [{
						title: '发票清单',
						type: 1,
						checked: true,
					},
					{
						title: '明细清单',
						type: 2,
						checked: false
					},
          {
						title: '开票申请',
						type: 3,
						checked: false
					},
				],
				checkData: []
			};
		},
		created() {
			// this.getdayValue();

		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.value = this.getYM();
			this.pointBillGetList();
			this.getShopList();
		},
		methods: {
      // 开票申请
      openInvoicing() {
        this.openshowInvoicing = true
      },
      //点击下载
      showImgClick(val) {
        // console.log( JSON.stringify(val))
        window.open(val)
      },
			gokuaidilink(e) {
				console.log(e.fPosturl)
				window.open(e.fPosturl);
			},
			searchqingdan() {
				console.log(this.listShow == 2)
				if (this.listShow == 0) {
					this.pointBillGetList()
					return;
				}
				if (this.listshow == 1) {
					this.pointBillGetList1()
					return;
				}
        if(this.listShow == 2){
          this.getsecond()
        }
			},
      getsecond() {
        // if(this.shopList==''){
        //   return 
        // }
        this.$refs.customertable.getTable({
            fShopUnitID: this.shopList == '' ? 5 : this.shopList,
            fYearPeriod: this.getYM(this.value)
          })
      },
			//获取当前时间
			getdayValue() {
				let a = new Date()
				this.defaultTime = this.getDate(a)
			},
			select(page) {
				this.page = page;
				if (this.listShow == 0) {
					this.pointBillGetList()
					return;
				}

				if (this.listshow == 1) {
					this.pointBillGetList1()
					return;
				}
        if (this.listShow == 2) {
          this.getsecond()
          return
        }
			},
			//获取统计报表 
			pointBillGetList() {
				this.ApiRequestPostNOMess(
					"api/mall/ebshop/invoice-brief/get-list-invoice-check-list", {
						"fYearPeriod": this.getYM(this.value),
						"fCompanyID": this.shopList == '' ? '5' : this.shopList,
						maxResultCount: this.maxResultCount1,
						skipCount: (this.page1 - 1) * this.maxResultCount1,
					}
				).then(
					(result) => {
						if (result.obj.items.length > 0) {
							for (let item of result.obj.items) {
								item.fNeedRepaymentDate = this.getTime(item.fNeedRepaymentDate).substring(0, 10);
								item.fGoodsPrice = parseFloat(item.fGoodsPrice).toFixed(2);
								item.fMoneyTax = this.NumFormat(parseFloat(item.fMoneyTax).toFixed(2));
								if(item.fKeepDate !=null){
									item.fKeepDate = this.getDate(item.fKeepDate); //开票日期
								}
								item.fSigninTime = item.fSigninTime ? this.getTime(item.fSigninTime) : ''; //签收时间
							}
							this.total = result.obj.totalCount;
							this.tableData1 = result.obj.items;
              this.tableData1.forEach(item=>{
               if(item.fPdfUrl == null){
                item.fPdfUrl = ''
               }
              })
						} else {
							this.total1 = result.obj.totalCount;
							this.tableData1 = [];
						}

					},
					(error) => {

					}
				);
			},
			//获取发票明细  等待后端接口
			pointBillGetList1() {
				this.ApiRequestPostNOMess(
					"api/mall/ebInvoice/detail/get-invoice-detail", {
						"fYearPeriod": this.getYM(this.value),
						"fCompanyID": this.shopList == '' ? '5' : this.shopList,
						maxResultCount: this.maxResultCount,
						skipCount: (this.page - 1) * this.maxResultCount
					}
				).then(
					(result) => {
						if (result.obj.items.length > 0) {
							for (let item of result.obj.items) {
								item.fNeedRepaymentDate = this.getTime(item.fNeedRepaymentDate).substring(0, 10);
								item.fGoodsPrice = parseFloat(item.fGoodsPrice).toFixed(2);
								item.fMoneyTax = this.NumFormat(parseFloat(item.fMoneyTax).toFixed(2));
							}
							this.total = result.obj.totalCount;
							this.tableData = result.obj.items;
							console.log('数据返回', this.tableData)
						} else {
							this.total = result.obj.totalCount;
							this.tableData = [];
						}

					},
					(error) => {

					}
				);
			},
			//获取店铺列表
			getShopList() {
				this.ApiRequestPostNOMess(
					"api/mall/ebInvoice/detail/get-company-list", {}
				).then(
					(result) => {
						console.log(result)
						if (result.obj != '') {
							this.shopArr = [];
							this.shopArr = JSON.parse(result.obj)
              console.log(this.shopArr)
              if(this.shopArr.length > 0){
							this.shopList = this.shopArr[0].FCompanyID;
              }
							console.log('this.shopArr', this.shopArr);
						}
					},
					(error) => {}
				);
			},
			downpdf() {
				this.pdfClick = false;
				this.ApiRequestPost("/api/mall/ebInvoice/detail/down-pdf-invoice-detail", {
					"fYearPeriod": this.getYM(this.value),
					"fCompanyID": this.shopList == '' ? '5' : this.shopList
				}).then(
					(res) => {
						window.open(res.obj, '_blank')
						this.pdfClick = true;
					},
					(error) => {
						this.pdfClick = true;
					}
				);
			},
			downexcl() {

				let urldownload = "";
				let models = [{
						"key": "fDeliveryOrderNumber",
						"value": "出库单号"
					},
					{
						"key": "fGoodsFullName",
						"value": "商品名称"
					},
					{
						"key": "fKeepDate",
						"value": "出库日期"
					},
					{
						"key": "fDepotName",
						"value": "仓库"
					},
					{
						"key": "fOrderNumber",
						"value": "订单号"
					},
					{
						"key": "fPlateNumber",
						"value": "车号"
					},
					{
						"key": "fDeliveryAmount",
						"value": "出库量(吨)"
					},
					{
						"key": "fGoodsPrice",
						"value": "出库价(元)"
					},
					{
						"key": "fMoneyTax",
						"value": "金额(元)"
					}
				]
				// if(this.listShow == 0){
				// 	// 统计报表
				// 	models = [
				// 	    {
				// 	      "key": "fDeliveryOrderNumber",
				// 	      "value": "店铺"
				// 	    },
				// 			{
				// 			  "key": "fGoodsFullName",
				// 			  "value": "商品名称"
				// 			},
				// 			{
				// 			  "key": "fKeepDate",
				// 			  "value": "出库日期"
				// 			},
				// 			{
				// 			  "key": "fDepotName",
				// 			  "value": "仓库"
				// 			},
				// 			{
				// 			  "key": "fOrderNumber",
				// 			  "value": "订单号"
				// 			},
				// 			{
				// 			  "key": "fPlateNumber",
				// 			  "value": "车号"
				// 			},
				// 			{
				// 			  "key": "fDeliveryAmount",
				// 			  "value": "出库量(吨)"
				// 			},
				// 			{
				// 			  "key": "fGoodsPrice",
				// 			  "value": "出库价(元)"
				// 			},
				// 			{
				// 			  "key": "fMoneyTax",
				// 			  "value": "金额(元)"
				// 			}
				// 	  ]
				// }else{
				// 	models = [
				// 		{
				// 		  "key": "fCompanyName",
				// 		  "value": "店铺"
				// 		},
				// 		{
				// 		  "key": "fSourceBillNumber",
				// 		  "value": "发票号"
				// 		},
				// 		{
				// 		  "key": "fSumAmount",
				// 		  "value": "商品总数量"
				// 		},
				// 		{
				// 		  "key": "fSumMoneyTax",
				// 		  "value": "总含税金额(元)"
				// 		},
				// 		{
				// 		  "key": "fSumMoney",
				// 		  "value": "总不含税金额(元)"
				// 		},
				// 		{
				// 		  "key": "fSumMoneyTax",
				// 		  "value": "总含税金额(元)"
				// 		},
				// 		{
				// 		  "key": "fPostNumber",
				// 		  "value": "快递单号"
				// 		},
				// 		{
				// 		  "key": "fKeepDate",
				// 		  "value": "开票时间"
				// 		},

				// 	]
				// }

				console.log('4564');
				this.excelClick = false;
				this.ApiRequestPost("/api/mall/ebInvoice/detail/down-execl-invoice-detail", {
					"fYearPeriod": this.getYM(this.value),
					"fCompanyID": this.shopList == '' ? '5' : this.shopList,
					"model": models,
				}).then(
					(res) => {
						window.open(res.obj, '_self')
						this.excelClick = true;
					},
					(error) => {
						this.excelClick = true;
					}
				);
			},
			//tab点击事件
			selecttab(e) {
				console.log(e, '111111');
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.listShow = e;
				if (this.listShow == 1) {
					this.pointBillGetList1()
				} else if (this.listShow == 0) {
					this.pointBillGetList()
				} else {
          this.$nextTick(()=>{
            // alert(1)
            console.log(this.$refs.customertable !=undefined)
          if(this.$refs.customertable !=undefined){
            this.getsecond()
          }
          })
          // 
        }
			},
			NumFormat(value) {
				if (!value) return '0.00';
				value = Number(value).toFixed(2).toString();
				var intPart = parseInt(Number(value)); //获取整数部分
				var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
				var floatPart = ".00"; //预定义小数部分
				var value2Array = value.split(".");
				//=2表示数据有小数位
				if (value2Array.length == 2) {
					floatPart = value2Array[1].toString(); //拿到小数部分
					if (floatPart.length == 1) { //补0,实际上用不着
						return intPartFormat + "." + floatPart + '0';
					} else {
						return intPartFormat + "." + floatPart;
					}
				} else {
					return intPartFormat + floatPart;
				}
			},
			Sign() {
				if (this.checkData.length == 0) {
					this.$message('请选择要签收的数据')
					return
				}
				let data = []
				this.checkData.map(item => {
					let obj = {
						fInvoiceID: item.fInvoiceID
					}
					data.push(obj)
				})
				this.$confirm("是否确认签收", "温馨提示", {
						confirmButtonText: "确定签收",
						cancelButtonText: "取消签收",
						type: "warning",
					}).then(()=>{
						this.ApiRequestPost("api/mall/ebshop/invoice-brief/invoice-signiner", data).then(res => {
							console.log(res)
							if(res){
								this.$message(res.message)
								this.searchqingdan()
							}
						})
					}).catch(()=>{
						
					})
				
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.checkData = val;
				console.log(this.checkData)
			},
			// 全选
			handleSelectionAll(val) {
				this.checkData = val
				console.log(this.checkData)
			},
		},

	};
</script>
<style scoped lang="scss">
	.margb {
		margin-bottom: 20px;
	}

	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
		// display: none;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
		
	}
	.inputBor {
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
	}
	.tabone-num {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		color: #6C40BF;
		text-align: center;
	}

	.tabone-con {
		font-size: 14px;
		color: #6C40BF;
		border-bottom: none;
	}

	.tabone-num1 {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		color: #333333;
	}

	.tabone-con1 {
		font-size: 14px;
		color: #333333;
		text-align: center;
		border-bottom: none;
	}

	.date {
		position: relative;
		top: 2px;
		left: 285px;
	}

	.myPointsContent {
		width: 917px;
		min-height: 485px;
		background-color: #FFFFFF;
		// padding:0px 13px;
	}

	.allOrder-btn {
		display: flex;
		// position: relative;	
		// left: 295px;
	}

	.allOrder-btn div:nth-child(1) {
		width: 75px;
		height: 32px;
		margin-left: 10px;
		margin-right: 10px;
		text-align: center;
		line-height: 32px;
	}

	.allOrder-btn div:nth-child(2) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
		margin-right: 10px;
	}

	.allOrder-btn div:nth-child(3) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
		margin-right: 10px;
	}

	.allOrder-btn div:nth-child(4) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
	}

	.block {
		margin-left: 260px;
		margin-right: 10px;
	}

	::v-deep .el-table__row {
		height: 38px;
	}

	.red {
		color: #ff0000;
	}

	.black {
		color: #000;
	}

	/* tab切换标题 */
	.tabTitleRow {
		height: 40px;
		background-color: #F8F9FE;
		border: 1px solid #eee;
		margin-top: 17px;
		box-sizing: border-box;
		margin-bottom: 17px;
	}

	::v-deep .el-table .cell {
		white-space: nowrap;
		padding-left:5px !important;
		padding-right:5px !important;
	}

	::v-deep .el-table thead {
		font-size: 13px;
		color: #000000;
		font-weight: 500;
		text-align: center;
	}

	::v-deep .el-table th {
		height: 38px;
		padding: 0px 0px;
		text-align: center;
		color:#666666;
		font-weight: normal;
	}
	::v-deep .el-table td,
	::v-deep .el-table th {
		height: 38px;
		padding: 0px 0px;
		color:#666666;
		font-weight: normal;
		font-size: 12px;
	}

	::v-deep .el-table th {
		 text-align: center;
	}
	::v-deep .is-leaf {
		border-right: 0px solid transparent;
		background-color: #F2F2F2;
	}

	.tabTitleRow div {
		width: 108px;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #666;
		text-align: center;
		box-sizing: border-box;
	}
	

	.tabTitleRow div:first-child {
		border-left: none !important;
	}

	.tabTitleRow .checkTitleSty {
		@include themify($themes) {
			color: themed("themes_color");
		}

		font-weight: bold;
		background-color: #fff;
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.line-height39 {
		line-height: 39px;
	}

	.billtext {
		line-height: 36px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.aaa {
		width: 800px;
		justify-content: space-evenly;
		margin-top: 20px;
		border-bottom: none;
	}

	.tabOne {
		width: 100%;
		height: 135px;
		background: #F8F5FC;
		border-radius: 2px;
		border: 1px solid #6C40BF;
		margin-top: 10px;
		position: relative;
	}

	.tabOne-Title {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 15px;
		color: #6C40BF;
		margin: 10px 0px 10px 10px;
	}

	.tabOne img {
		width: 180px;
		height: 119px;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.topbgc {
		// width: 917px;
		height: 35px;
		background: #f8f9fe;
		padding: 0 15px;
		margin: 11px auto;
	}

	::v-deep.el-input--suffix .el-input__inner {
		line-height: 32px !important;
		height: 32px !important;
	}

	::v-deep .el-input__icon {
		line-height: 32px !important;
	}

	.billsitems {
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #f2f2f2;
	}

	.allOrder-btn .zad {
		/* position: absolute;
		left: 1095px; */
	}

	.allOrder-btn .ad {
		/* position: absolute;
		left: 1175px; */
	}
	// /deep/  .el-input{
	// 	width: 185px !important;
	// }
	/deep/ .el-pagination {
		padding: 3px  5px;
	}
</style>
