<template>
  <div>
    <el-dialog title="开票明细" :visible.sync="open" width="60%" :before-close="handleClose" :close-on-click-modal="false"
      :close-on-press-escape="false" v-loading="loading" top="6vh">
      <!-- <span>这是一段信息</span> -->
      <div class="flex-column-justify-center ">
        <div class="background" v-loading="mainLoading">
          <div class="Invoicingbackground_title flex-row-space-between-center margin-b-15 margin-t-10">
            <div class="Invoicingbackground_title_left" style="color:#333333">开票明细</div>
            <el-button class="Invoicingbackground_title_right" type="primary" plain v-if="formdata.fStatus == 2"
              @click="upload">下载</el-button>
          </div>
          <div class="save_form ">
            <div class="flex-row-align-center margin-b-20">
              <div class="save_form_title1" v-if="formdata.fCommerceTypeID != 1">
                企业名称
              </div>
              <div class="save_form_title1" v-if="formdata.fCommerceTypeID == 1">
                个人名称
              </div>
              <div class="save_form_title2 ">
                {{ formdata.fCreator }}
              </div>
            </div>
            <div class="flex-row-align-center margin-b-20" style="line-height: 38px;">
              <div class="save_form_title1">申请单状态</div>
              <div class="save_form_title2 " style="font-weight: 600;">
                {{formdata.fStatusName}}
              </div>
            </div>
            <div class="flex-row-align-center margin-b-20" style="line-height: 38px;">
              <div class="save_form_title1" v-if="formdata.fCommerceTypeID != 1">
                统一信用代码
              </div>
              <div class="save_form_title1" v-if="formdata.fCommerceTypeID == 1">
                身份号码
              </div>
              <div class="save_form_title2 ">
                {{ formdata.fIdentityCode }}
              </div>
            </div>
            <div class="flex-row-align-center margin-b-20" style="line-height: 38px;">
              <div class="save_form_title1">发票类型</div>
              <div class="save_form_title2 ">
                {{formdata.fInvoiceClassName}}
              </div>
            </div>
            <div class="flex-row-align-center margin-b-20" style="line-height: 38px;">
              <div class="save_form_title1">商品总数量</div>
              <div class="save_form_title2 " style="color: #FF6600;font-weight: 700;">
                {{NumFormat(formdata.fSumAmount)}}吨
              </div>
            </div>
            <div class="flex-row-align-center margin-b-20" style="line-height: 38px;">
              <div class="save_form_title1">总金额</div>
              <div class="save_form_title2 " style="color: #FF6600;font-weight: 700;">
                {{NumFormat(formdata.fSumMoney)}}元
              </div>
            </div>
            <div class="flex-row-align-center margin-b-20" style="line-height: 38px;">
              <div class="save_form_title1">备注</div>
              <div class="save_form_title2 oneline-show-hide">
                {{formdata.fRemark}}
              </div>
            </div>

            <div class="save_form_title1" style="color:#333333;font-weight: 700">
              出库单：{{total}}
            </div>
          </div>
        </div>
        <div v-loading="loading" class="margin-t-20">
          <el-table :data="tableData" border style="width: 98%;margin-bottom:20px" height="200">
            <el-table-column prop="fDeliveryOrderNumber" label="派车单号" width="135" align="center"></el-table-column>
            <el-table-column prop="fOrderNumber" label="订单号" width="135" align="center"></el-table-column>
            <el-table-column prop="fKeepDate" label="发货日期" width="100" align="center"></el-table-column>
            <!-- <el-table-column prop="fGradeName" label="客户等级" width="100" align="center"></el-table-column> -->
            <el-table-column prop="fTradingModel" label="交易模式" width="100" align="center"></el-table-column>
            <el-table-column prop="fDepotName" label="仓库" width="100" align="left"></el-table-column>
            <el-table-column prop="fGoodsFullName" label="商品名称" width="200" align="left"></el-table-column>
            <el-table-column prop="fGoodsPropertieData" label="包装方式" width="80" align="center"></el-table-column>
            <el-table-column prop="fWareNumber" label="水泥编号" width="100" align="center"></el-table-column>
            <el-table-column prop="fDelivery" label="提货方式" width="80" align="center"></el-table-column>
            <el-table-column prop="fUnitName" label="计量单位" width="80" align="center"></el-table-column>
            <el-table-column prop="fScheduleVanAmount" label="派车量" width="100" align="right"></el-table-column>
            <el-table-column prop="fGross" label="毛重" width="100" align="right"></el-table-column>
            <el-table-column prop="fTare" label="皮重" width="100" align="right"></el-table-column>
            <el-table-column prop="fDeliveryAmount" label="实发量" width="100" align="right"></el-table-column>
            <el-table-column prop="fGoodsAgePrice" label="货款价" width="100" align="right"></el-table-column>
            <el-table-column prop="fSettlePrice" label="结算单价" width="100" align="right"></el-table-column>
            <el-table-column prop="fcFreightPrice" label="运价" width="100" align="right"></el-table-column>
            <el-table-column prop="fUsePoint" label="使用积分数" width="100" align="right"></el-table-column>
            <!-- <el-table-column prop="fPointPrice" label="积分价" width="100" align="right"></el-table-column> -->
            <el-table-column prop="fPointMoney" label="积分金额" width="100" align="right"></el-table-column>
            <el-table-column prop="fGoodsMoney" label="货款金额" width="100" align="right"></el-table-column>
            <el-table-column prop="fcFreightMoney" label="运费金额" width="80" align="right"></el-table-column>
            <el-table-column prop="fTareTime" label="皮重时间" width="162" align="center"></el-table-column>
            <el-table-column prop="fGrossTime" label="毛重时间" width="162" align="center"></el-table-column>
            <el-table-column prop="fPlateNumber" label="车号" width="100" align="left"></el-table-column>
            <el-table-column prop="fDriver" label="司机" width="130" align="left"></el-table-column>
            <el-table-column prop="fDriverPhone" label="司机电话" width="130" align="left"></el-table-column>
            <!-- <el-table-column prop="fcsPhone" label="客服电话" width="130" align="left"></el-table-column> -->
            <el-table-column prop="fConsignee" label="收货人" width="80" align="left"></el-table-column>
            <el-table-column prop="fConsigneePhone" label="收货人电话" width="130" align="left"></el-table-column>
            <el-table-column prop="fProjectName" label="工程名称" width="220" align="left"></el-table-column>
            <el-table-column prop="fGovernmenArea" label="行政区域" width="140" align="left"></el-table-column>
            <el-table-column prop="fDeliveryAddress" label="收货地址" width="500" align="left"></el-table-column>
            <el-table-column prop="fCreator" label="制单人" width="100" align="center"></el-table-column>
            <el-table-column prop="fCreateTime" label="制单时间" width="162" align="center"></el-table-column>
          </el-table>
          <pagination :total="total" :currentPage="page" :pagesize="maxResultCount" @handleCurrentChange="handleCurrentChange" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import pagination from '@/components/pagination/pagination';
export default {
  components: {
    pagination
  },
  props: {
    rowId: {
      default: "",
    },
    open: {
      type: Boolean,
      default: false
    },

  },
  data () {
    return {
      total: 0, // 条数
      page: 1, // 页数
      maxResultCount: 20,
      tableData: [],
      loading: false,
      mainLoading: false,
      formdata: {},
    }
  },
  computed: {
    ...mapGetters(['getThemeName', 'getUserInfo']),
    themeClass () {
      return `theme-${this.getThemeName}`
    },
  },
  mounted () {
    this.getTable()
  },
  methods: {
    NumFormat (value) {
      if (!value) return '0.00'
      value = Number(value)
        .toFixed(2)
        .toString()
      var intPart = parseInt(Number(value)) //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
      var floatPart = '.00' //预定义小数部分
      var value2Array = value.split('.')
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString() //拿到小数部分
        if (floatPart.length == 1) {
          //补0,实际上用不着
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    },
    getTable () {
      this.loading = true
      this.ApiRequestPostNOMess("/api/mall/ebbalance/brief-apply-bill/get", {
        id: this.rowId
      }).then(
        (res) => {
          this.formdata = res.obj
          this.getDetail()
        },
        (error) => {
          this.loading = false
        }
      );
      console.log(this.rowId)
    },
    getDetail () {
  
      this.ApiRequestPostNOMess("/api/mall/ebsale/deliver-order/get-by-invoice-applyID", {
        fApplyID: this.rowId,
        maxResultCount: this.maxResultCount,
        skipCount: (this.page - 1) * this.maxResultCount,
      }).then(
        (res) => {
          console.log(res)
          // this.tableData = res.obj.items
          this.total = res.obj.totalCount
          if(res.obj.items.length == 0){
            this.tableData = []
          }else{
            this.tableData = res.obj.items
            this.tableData.forEach(item=>{
              item.fScheduleVanAmount = this.NumFormat(item.fScheduleVanAmount)
              item.fGross = this.NumFormat(item.fGross)
              item.fDeliveryAmount = this.NumFormat(item.fDeliveryAmount)
              item.fGoodsAgePrice = this.NumFormat(item.fGoodsAgePrice)
              item.fSettlePrice = this.NumFormat(item.fSettlePrice)
              item.fcFreightPrice = this.NumFormat(item.fcFreightPrice)
              item.fUsePoint = this.NumFormat(item.fUsePoint)
              item.fTare = this.NumFormat(item.fTare)
              item.fKeepDate = this.getDate(item.fKeepDate)
              item.fPointMoney = this.NumFormat(item.fPointMoney)
              item.fGoodsMoney = this.NumFormat(item.fGoodsMoney)
              item.fcFreightMoney = this.NumFormat(item.fcFreightMoney)
            })
          }
          this.loading = false
        },
        (error) => {
          this.loading = false
        }
      );
    },
    handleCurrentChange(val) {
      this.page = val
      this.getDetail()
    },
    handleClose () {
      this.$emit('close')
    },
    upload () { 
      this.mainLoading = true
      this.loading = true
      this.ApiRequestPostNOMess("/api/mall/ebshop/invoice-brief/get-list-by-sourcebill", {
        fApplyID: this.formdata.fApplyID,
        frv: this.formdata.frv,
        rowStatus: this.formdata.rowStatus
      }).then(
        (res) => {
          console.log(res)
          this.mainLoading = false
          this.loading = false
          if(res.obj.length> 0){
            res.obj.forEach(item=>{
              if(item.fPdfUrl != ''){
                window.open(item.fPdfUrl)
              }
            })
          }

        },
        (error) => {
          this.mainLoading = false
          this.loading = false
        }
      );
    },

  }
}
</script>

<style scoped lang="scss">
.background {
  width: 98%;
  // height: 40px;
  border-top: 1px solid #98a2b1;
  .Invoicingbackground_title {
    width: 100%;
    height: 37px;
    margin-top: 15px;
    // border: 1px solid;
    .Invoicingbackground_title_left {
      height: 24px;
      line-height: 26px;
      font-family: "Arial Negreta", "Arial Normal", "Arial";
      font-weight: 700;
      font-style: normal;
      font-size: 15px;
    }
    .Invoicingbackground_title_right {
      // width: 88px;
      color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      background: rgba(0, 102, 255, 1);
      border-radius: 2px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      // color: #0066ff;
      padding: 8px 35px;
      font-family: "Arial Normal", "Arial";
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
    }
  }
}
.save_form {
  width: 88%;

  // height: 200px;
  // border: 1px solid red;
  .save_form_title1 {
    height: 20px;
    font-size: 16px;
    color: #8a95a6;
    line-height: 20px;
  }
  .save_form_title2 {
    height: 20px;
    margin-left: 10px;
    text-align: left;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #333333;
    line-height: 20px;
  }
}
/deep/.el-dialog__body {
  padding-top: 5px;
}
/deep/.el-dialog__header{
  color:#333333;
  font-weight: 700;
  font-size: 23px;
}
/deep/.el-dialog__close{
  color:#333333;
  font-weight: 700;
  font-size: 23px;
}
</style>
