<template>
  <!-- 申请开票 -->
  <div :class="themeClass">
    <div class="Invoicingbackground" v-loading="loading">
      <div class="Invoicingbackground_title flex-row-space-between-center margin-b-15">
        <div class="Invoicingbackground_title_left" v-if="active!=3">申请开票</div>
        <el-button class="Invoicingbackground_title_right" v-if="active!=3" plain @click="close">取消开票</el-button>
      </div>
      <div class="Invoicingbackground_constant margin-b-10">
        *提示：当月在线申请开票，还有<i class="red_constant"> {{monthCount}} </i>次
      </div>
      <div class="yijia-step bg-fff padding-t-15">
        <el-steps :active="active" :align-center="true" finish-status="success">
          <el-step title="选择出库单"></el-step>
          <el-step title="确定开票信息"></el-step>
          <el-step title="审核"></el-step>
        </el-steps>
      </div>
      <!-- 选择出库单 -->
      <div v-if="active == 1">
        <div class="flex-row-align-flex-end margb" style="margin-top: 10px;">
          <div class=" margin-r-10 " style="display: flex;align-items: center;">
            <div style="color: #666666;margin-right: 10px;min-width: 40px">
              店铺
            </div>
            <el-select v-model="shopList" placeholder="请选择店铺" ref="fBankTypeName">
              <el-option v-for="(item, index) in shopArr" :key="index" :label="item.fShopName"
                :value="item.fShopID"></el-option>
            </el-select>
          </div>
          <div class="" style="display: flex;align-items: center;">
            <div style="color: #666666;margin-right: 10px;min-width: 40px">
              年月
            </div>
            <el-select v-model="timevalue" placeholder="请选择店铺" ref="fBankTypeName" @change="getTable()"> 
            <el-option v-for="(item, index) in yearMonth" :key="index" :label="item.fYearPeriod"
                :value="item.fYearPeriod"></el-option>
            </el-select>    
            <!-- <el-date-picker v-model="timevalue" type="month" placeholder="选择月" value-format="yyyy-MM" :clearable='false' :editable='false'  :picker-options="pickerOptions">
            </el-date-picker> -->
          </div>
          <div class="search-box margin-l-10">
            <el-input style="width: 100%" prefix-icon="el-icon-search" v-model.trim="searchText"
              placeholder="派车单号/订单号/仓库">
            </el-input>
          </div>
          <div class="pointer search-btn margin-l-10 text-center" :v-loading="loading" @click="getTable">
            搜索
          </div>
        </div>
        <div style="width: 100%">
          <!-- :row-key="fDeliveryOrderID" -->
          <el-table class="margin-b-10" :data="tableData" stripe border :row-style="{ height: '36px' }"
            ref="tableborder" @selection-change="handleSelectionChange"  :cell-style="cell"
            @select="itemHandleSelectionChange" @select-all='itemHandleSelectionChangeall' :header-cell-style="{
              'text-align': 'center',
              background: '',
              'font-weight': '500',
              color: '#000',
              'font-size': '13px',
            }">
            <el-table-column type="selection" fixed width="27" align="center" :selectable="checkboxInit">
              <!-- <template></template> -->
            </el-table-column>
            <el-table-column prop="fDeliveryOrderNumber" label="派车单号" width="165" align="center"></el-table-column>
            <el-table-column prop="fOrderNumber" label="订单号" width="165" align="center"></el-table-column>
            <!-- <el-table-column
              prop="fDepotBillNumber"
              label="生产厂提货单号"
              width="165"
              align="center"
            ></el-table-column> -->
            <el-table-column prop="fKeepDate" label="发货日期" width="120" align="center"></el-table-column>
            <!-- <el-table-column prop="fGradeName" label="客户等级" width="100" align="center"></el-table-column> -->
            <el-table-column prop="fTradingModel" label="交易模式" width="100" align="center"></el-table-column>
            <el-table-column prop="fDepotName" label="仓库" width="100" align="left"></el-table-column>
            <el-table-column prop="fGoodsFullName" label="商品名称" width="200" align="left"></el-table-column>
            <el-table-column prop="fGoodsPropertieData" label="包装方式" width="80" align="center"></el-table-column>
            <el-table-column prop="fWareNumber" label="水泥编号" width="100" align="center"></el-table-column>
            <el-table-column prop="fDelivery" label="提货方式" width="80" align="center"></el-table-column>
            <el-table-column prop="fUnitName" label="计量单位" width="80" align="center"></el-table-column>
            <el-table-column prop="fScheduleVanAmount" label="派车量" width="100" align="right"></el-table-column>
            <el-table-column prop="fGross" label="毛重" width="100" align="right"></el-table-column>
            <el-table-column prop="fTare" label="皮重" width="100" align="right"></el-table-column>
            <el-table-column prop="fDeliveryAmount" label="实发量" width="100" align="right"></el-table-column>
            <el-table-column prop="fGoodsAgePrice" label="货款价" width="100" align="right"></el-table-column>
            <el-table-column prop="fSettlePrice" label="结算单价" width="100" align="right"></el-table-column>
            <el-table-column prop="fcFreightPrice" label="运价" width="100" align="right"></el-table-column>
            <el-table-column prop="fUsePoint" label="使用积分数" width="100" align="right"></el-table-column>
            <!-- <el-table-column prop="fPointPrice" label="积分价" width="100" align="right"></el-table-column> -->
            <el-table-column prop="fPointMoney" label="积分金额" width="100" align="right"></el-table-column>
            <el-table-column prop="fGoodsMoney" label="货款金额" width="100" align="right"></el-table-column>
            <el-table-column prop="fcFreightMoney" label="运费金额" width="80" align="right"></el-table-column>
            <el-table-column prop="fTareTime" label="皮重时间" width="192" align="center"></el-table-column>
            <el-table-column prop="fGrossTime" label="毛重时间" width="192" align="center"></el-table-column>
            <el-table-column prop="fPlateNumber" label="车号" width="100" align="left"></el-table-column>
            <el-table-column prop="fDriver" label="司机" width="130" align="left"></el-table-column>
            <el-table-column prop="fDriverPhone" label="司机电话" width="130" align="left"></el-table-column>
            <!-- <el-table-column prop="fcsPhone" label="客服电话" width="130" align="left"></el-table-column> -->
            <el-table-column prop="fConsignee" label="收货人" width="80" align="left"></el-table-column>
            <el-table-column prop="fConsigneePhone" label="收货人电话" width="130" align="left"></el-table-column>
            <el-table-column prop="fProjectName" label="工程名称" width="220" align="left"></el-table-column>
            <el-table-column prop="fGovernmenArea" label="行政区域" width="140" align="left"></el-table-column>
            <el-table-column prop="fDeliveryAddress" label="收货地址" width="500" align="left"></el-table-column>
            <el-table-column prop="fCreator" label="制单人" width="100" align="center"></el-table-column>
            <el-table-column prop="fCreateTime" label="制单时间" width="192" align="center"></el-table-column>
          </el-table>
        </div>
        <div class="margin-b-15">
          <pagination :total="total" :currentPage="page" :pagesize="maxResultCount"
            @handleCurrentChange="handleCurrentChange" />
        </div>
        <div class="Addnumber flex-space-center margin-b-20">
          <div class="Addnumber_content">
            <div class="Addnumber_content_three flex-row">
              已选出库单：
              <div class="table-alldocument">{{ oldSelection.length }}</div>
            </div>
          </div>
          <div class="Addnumber_content">
            <div class="Addnumber_content_three flex-row">
              数量合计：
              <div class="table_ton">{{ NumFormat(reversedMessage) }}吨</div>
            </div>
          </div>
          <div class="Addnumber_content">
            <div class="Addnumber_content_three flex-row">
              总金额：
              <div class="table-alldocument" style="color: #FF0000;">
                {{ NumFormat(reverfGoodsMoney) }}元
              </div>
            </div>
          </div>
        </div>
        <div class="next_button pointer" @click="nextAdd">下一步</div>
      </div>
      <!-- 选择出库单 -->
      <!-- 确认开票信息 -->
      <div v-if="active == 2" class="margin-t-10">
        <div class="flex-row margin-b-20">
          <div class="save_paper">信息汇总</div>
          <div class="save_form ">
            <div class="save_form_title1 margin-b-10" v-if="getUserInfo.fCommerceTypeID != 1">
              企业名称
            </div>
            <div class="save_form_title1 margin-b-10" v-if="getUserInfo.fCommerceTypeID == 1">
              个人名称
            </div>
            <div class="save_form_title2 margin-b-20">
              {{ getUserInfo.fUserName }}
            </div>
            <div class="save_form_title1 margin-b-10" v-if="getUserInfo.fCommerceTypeID != 1">
              统一信用代码
            </div>
            <div class="save_form_title1 margin-b-10" v-if="getUserInfo.fCommerceTypeID == 1">
              身份号码
            </div>
            <div class="save_form_title2 margin-b-20">
              {{ getUserInfo.cardId }}
            </div>
            <div class="save_form_title1 margin-b-10">发票类型</div>
            <div class="save_form_title2 margin-b-20">
              {{ fInvoiceClassName }}
            </div>
            <div class="save_form_title1 margin-b-10">商品总数量</div>
            <div class="save_form_title2 margin-b-20" style="color: #FF6600;font-weight: 700;">
              {{ NumFormat(reversedMessage) }}吨
            </div>
            <div class="save_form_title1 margin-b-10">总金额</div>
            <div class="save_form_title2 margin-b-20" style="color: #FF6600;font-weight: 700;">
              {{ NumFormat(reverfGoodsMoney) }}元
            </div>
            <div class="save_form_title1 margin-b-10" style="color:#333333">
              备注
            </div>
            <el-input style="border: 1px solid #dcdfe6;border-radius: 4px;" type="textarea" :rows="2" maxlength="220"
              placeholder="请输入内容" v-model.trim="textarea">
            </el-input>
          </div>
        </div>
        <div class="flex-space-around" style="padding: 0px 20%">
          <div class="next_button pointer margin-r-10" style="width: 223px;" @click="lastAdd">
            上一步
          </div>
          <div class="next_button pointer myDiv" type="button" style="width: 223px;" :loading="submitloading"
            @click="submit">
            提交
          </div>
        </div>
      </div>
      <!-- 确认开票信息 -->
      <div v-if="active == 3">
        <div class="margin-t-30 ">
          <div style="display: flex;justify-content: center;">
            <img src="../../../assets/shenhezhong.png" alt="" style=" width:223px;height:178px;" />
          </div>
          <div class="size-16"
            style="display: flex;justify-content: center;font-family: 'Arial Normal', 'Arial'; font-weight: 700;font-style: normal;font-size: 17px;color: #333333;">
            正在审核中...
          </div>
          <div class="size-14" style="display: flex;justify-content: center;">
            我们将尽快为您审核，请耐心等待！
          </div>
          <div class="btn margin-t-30" style="display: flex;justify-content: center;">
            <el-button @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import pagination from '@/components/pagination/pagination'
export default {
  components: {
    pagination,
  },
  data () {
    return {
      // pickerOptions: {
      //     disabledDate(time) {
      //       console.log(time,this)
      //       return time.toString().slice(11,15)+ time.toString().slice(4,8) != new Date().toString().slice(11,15)+new Date().toString().slice(4,8)
      //     },
      // },
      textarea: '',
      searchText: '',
      timevalue: '',
      shopList: '',
      shopArr: [],
      loading: false,
      total: 0, // 条数
      page: 1, // 页数
      maxResultCount: 20,
      total1: 0, // 条数
      page1: 1, // 页数
      pdfClick: true, //导出pdf直到接口返回成功或者失败才能再次点击
      excelClick: true, //导出excel直到接口返回成功或者失败才能再次点击
      shopList: '',
      tableData: [],
      checkData: [],
      multipleSelection: [], // 多选的数据
      oldSelection: [],
      selectedlist: [],
      active: 1,
      fInvoiceClassName: '',
      submitloading: false,
      FbillNUmber: '',
      monthCount: 0,
      yearMonth: [],
    }
  },
  created () {
    // this.timevalue = this.getYM()
    this.getMonth()
    this.getApply()
    this.getInvoice()
    this.getNumber()
    this.getShopList()
  },
  computed: {
    ...mapGetters(['getThemeName', 'getUserInfo']),
    themeClass () {
      return `theme-${this.getThemeName}`
    },
    handleMonthChange(value) {
      console.log(value)
    },
    reversedMessage () {
      let data = 0
      console.log(this.oldSelection)
      if (this.oldSelection.length > 0) {
        this.oldSelection.some((item) => {
          item.fDeliveryAmount = item.fDeliveryAmount.replace(',', "");
          data += item.fDeliveryAmount * 1
        })
        return data
      } else {
        return 0
      }
    },
    reverfGoodsMoney () {
      let data = 0
      if (this.oldSelection.length > 0) {
        this.oldSelection.some((item) => {
          // item.fGoodsMoney = item.fGoodsMoney.replace(',', "");
          // console.log(item.fGoodsMoney)
          data += item.fGoodsMoney1 * 1
        })
        console.log(data)
        return data
      } else {
        return 0
      }
    },
  },
  mounted () {
    
   },
  methods: {
    disabledDateall () {},
    getMonth() {
      // /api/mall/sys/account-date/get-list-recently-twelve
      this.ApiRequestPostNOMess('/api/mall/sys/account-date/get-list-recently-twelve', {}).then(res => {
        console.log(res)
        this.yearMonth = res.obj
        this.timevalue = res.obj[0].fYearPeriod
        console.log(this.timevalue)
        this.getTable()
      })
    },
    //改变单行蓝色和绿色
    cell ({ row, column, rowIndex, columnIndex }) {
      if (row.fCustSign == 1) {
        return 'color: #98A2B1'
      }

    },
    checkboxInit (row, index) {
      // console.log(row.fCustSign == 1)
      if (row.fGoodsSign == 1) {
        return 0
      } else {
        return 1
      }
    },
    getApply () {
      this.ApiRequestPostNOMess('api/mall/ebbalance/brief-apply-bill/get-month-apply-count', {}).then(res => {
        console.log(res, '发票数')
        this.monthCount = res.obj.monthCount
      })
    },
    getNumber () {
      this.ApiRequestPostNOMess(
        'api/mall/ebbalance/brief-apply-bill/get-bill-number',
        {}
      ).then((res) => {
        console.log(res, '单据编码')
        this.FbillNUmber = res.obj
      })
    },
    //获取发票信息
    getInvoice () {
      this.ApiRequestPostNOMess(
        '/api/mall/ebcustomer/invoice-info/get',
        {}
      ).then(
        (result) => {
          console.log(result, '获取发票类型')
          this.fInvoiceClassName = result.obj.fInvoiceClassName
        },
        (rej) => {
          this.pageLoading = false
        }
      )
    },
    lastAdd () {
      this.active = 1

      if (this.tableData.length > 0) {
        this.selectedlist = []
        // console.log(this.oldSelection)
        this.oldSelection.forEach((item) => {
          this.selectedlist.push(item.fDeliveryOrderID)
        })
        console.log(1111, this.selectedlist, this.oldSelection)
        this.$nextTick(() => {
          this.tableData.some((item) => {
            console.log(
              this.selectedlist.indexOf(item.fDeliveryOrderID) != -1
            )
            //选中
            if (this.selectedlist.indexOf(item.fDeliveryOrderID) != -1) {
              this.$refs.tableborder.toggleRowSelection(item, true)
            } else {
              //未选中
              this.$refs.tableborder.toggleRowSelection(item, false)
            }
          })
        })

      }
      this.$forceUpdate()
    },
    submit () {
      this.loading = true
      let params = {}
      params.fSumAmount = this.reversedMessage
      params.fSumMoney = this.reverfGoodsMoney
      params.fBillNumber = this.FbillNUmber
      params.fShopUnitID = this.shopList
      params.fYearPeriod = this.timevalue
      params.fRemark = this.textarea
      params.fDeliveryOrderIDS = this.oldSelection.map(
        (item, index, arr) => item.fDeliveryOrderID
      )
      console.log(params)
      this.ApiRequestPost(
        '/api/mall/ebbalance/brief-apply-bill/create',
        params
      ).then(
        (result) => {
          console.log(result)
          this.active = 3
          this.loading = false
        },
        (error) => {
          this.loading = false
        }
      )
    },
    //获取店铺列表
    getShopList () {
      this.ApiRequestPostNOMess(
        'api/mall/ebsale/deliver-order/get-shoping-bill-statistics-shop',
        {}
      ).then(
        (result) => {
          console.log(result,'店铺')
          if (result.obj.length > 0) {
            this.shopArr = []
            this.shopArr = result.obj
							this.shopList = this.shopArr[0].fShopID 
            console.log('this.shopArr', this.shopArr)
          } else {
            this.shopArr = []
          }
        },
        (error) => { }
      )
    },
    close () {
      this.$emit('close')
    },
    nextAdd () {
      if (this.monthCount == 0) {
        return this.$message.warning('当月无可申请开票次数')
      }
      if (this.oldSelection.length == 0) {
        return this.$message.warning('请选择操作项')
      }
      this.active = 2
    },
    handleCurrentChange (val) {
      console.log(val)
      this.page = val
      this.getTable()
    },
    getTable () {
      if (this.timevalue == '') {
        return this.$message.warning('请选择年月')
      }
      // if (this.shopList == '') {
      //   return this.$message.warning('请选择店铺')
      // }
      console.log(this.timevalue)
      this.selectedlist = []
      // console.log(this.oldSelection)
      this.oldSelection.forEach((item) => {
        this.selectedlist.push(item.fDeliveryOrderID)
      })
      console.log(this.selectedlist)
      // /api/mall/ebsale/deliver-order/get-by-customer-and-yearperiod
      this.loading = true
      this.ApiRequestPostNOMess(
        '/api/mall/ebsale/deliver-order/get-by-customer-and-yearperiod',
        {
          fShopID: this.shopList == '' ? '5' : this.shopList,
          fYearPeriod: this.timevalue,
          maxResultCount: this.maxResultCount,
          skipCount: (this.page - 1) * this.maxResultCount,
          filter: this.searchText,
        }
      ).then(
        (result) => {
          console.log(result)
          if (result.obj.items.length > 0) {
            this.total = result.obj.totalCount
            this.tableData = result.obj.items
            this.tableData.some(item => {
              item.fScheduleVanAmount = this.NumFormat(item.fScheduleVanAmount)
              item.fGross = this.NumFormat(item.fGross)
              item.fDeliveryAmount = this.NumFormat(item.fDeliveryAmount)
              item.fGoodsAgePrice = this.NumFormat(item.fGoodsAgePrice)
              item.fSettlePrice = this.NumFormat(item.fSettlePrice)
              item.fcFreightPrice = this.NumFormat(item.fcFreightPrice)
              item.fUsePoint = this.NumFormat(item.fUsePoint)
              item.fTare = this.NumFormat(item.fTare)
              item.fKeepDate = this.getDate(item.fKeepDate)
              item.fPointMoney = this.NumFormat(item.fPointMoney)
              item.fGoodsMoney1 = item.fGoodsMoney
              item.fGoodsMoney = this.NumFormat(item.fGoodsMoney)
              item.fcFreightMoney = this.NumFormat(item.fcFreightMoney)

            })
            //这里刷新选选中状态
            this.$nextTick(() => {
              this.tableData.some((item) => {
                console.log(
                  this.selectedlist.indexOf(item.fDeliveryOrderID) != -1
                )
                //选中
                if (this.selectedlist.indexOf(item.fDeliveryOrderID) != -1) {
                  this.$refs.tableborder.toggleRowSelection(item, true)
                } else {
                  //未选中
                  this.$refs.tableborder.toggleRowSelection(item, false)
                }
              })
            })

            console.log('数据返回', this.tableData)
          } else {
            this.total = result.obj.totalCount
            this.tableData = []
          }
          this.loading = false
        },
        (error) => { }
      )
    },
    NumFormat (value) {
      if (!value) return '0.00'
      value = Number(value)
        .toFixed(2)
        .toString()
      var intPart = parseInt(Number(value)) //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
      var floatPart = '.00' //预定义小数部分
      var value2Array = value.split('.')
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString() //拿到小数部分
        if (floatPart.length == 1) {
          //补0,实际上用不着
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    itemHandleSelectionChangeall (list) {
      console.log(arguments, list)
      if (arguments[0].length != 0) {
        arguments[0].forEach((item) => {
          this.oldSelection.push(item)
        })
        this.oldSelection = this.oldSelection.filter(
          (item, index, arr) => arr.indexOf(item) === index
        )
      } else {
        this.tableData.forEach((item, index) => {
          this.oldSelection.forEach((item1, index1) => {
            if (item.fDeliveryOrderID == item1.fDeliveryOrderID) {
              this.oldSelection.splice(index1, 1)
            }
          })
        })
      }
      console.log(this.oldSelection)
      this.oldSelection = this.oldSelection.filter(
        (item, index, arr) => arr.indexOf(item) === index
      )
    },
    //单条选中处理
    itemHandleSelectionChange (list, item) {
      console.log(list, item)
      // 在函数代码中，使用特殊对象 arguments，开发者无需明确指出参数名，就能访问它们。
      console.log(arguments)
      if (list.includes(item)) {
        //勾选时做的事
        arguments[0].forEach((item) => {
          this.oldSelection.push(item)
        })
        this.oldSelection = this.oldSelection.filter(
          (item, index, arr) => arr.indexOf(item) === index
        )
      } else {
        //取消勾选时做的事，arguments[1]是当前取消勾选的项
        this.oldSelection.forEach((item, index) => {
          console.log(item)
          if (item.fDeliveryOrderID == arguments[1].fDeliveryOrderID) {
            this.oldSelection.splice(index, 1)
          }
        })
      }
    },
  },
}
</script>
<style scoped lang="scss">
.save_paper {
  text-align: center;
  width: 12%;
  // height: 200px;
  // border: 1px solid;
  font-family: "Arial Normal", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #333333;
}
.save_form {
  width: 88%;
  // height: 200px;
  // border: 1px solid red;
  .save_form_title1 {
    height: 18px;
    font-size: 16px;
    color: #8a95a6;
    line-height: normal;
  }
  .save_form_title2 {
    text-align: left;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
    color: #333333;
    line-height: normal;
  }
}
.Addnumber {
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: inherit;
  background-color: rgb(229, 239, 255);
  border: none;
  .Addnumber_content {
    height: 100%;
    width: 220px;
    // border: 1px solid;
    .Addnumber_content_three {
      // width: 33.33%;
      height: 100%;
      // border: 1px solid;
      font-family: "Arial Normal", "Arial";
      font-weight: 400;
      .table-alldocument {
        font-family: "Arial Negreta", "Arial Normal", "Arial";
        font-weight: 700;
        font-size: 16px;
        display: block;
      }
      .table_ton {
        font-family: "Arial Negreta", "Arial Normal", "Arial";
        font-weight: 700;
        font-size: 16px;
        color: #0066ff;
      }
    }
  }
}
.next_button {
  width: 409px;
  height: 45px;
  line-height: 45px;
  background: inherit;
  background-color: rgba(51, 102, 255, 1);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
  margin: 0 auto;
  color: #ffffff;
  font-family: "Arial Normal", "Arial";
  font-weight: 400;
  font-style: normal;
  line-height: 45px;
  text-align: center;
}
.Invoicingbackground_title {
  width: 100%;
  height: 37px;
  // border: 1px solid;
  .Invoicingbackground_title_left {
    height: 24px;
    font-family: "Arial Negreta", "Arial Normal", "Arial";
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
  }
  .Invoicingbackground_title_right {
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 102, 255, 1);
    border-radius: 2px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #0066ff;
    padding: 8px 20px;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
  }
}
.Invoicingbackground_constant {
  height: 16px;
  width: 100%;
  line-height: 16px;
  // border: 1px solid;
  font-family: "Arial Normal", "Arial";
  font-weight: 400;
  text-align: center;
  .red_constant {
    font-family: "Arial Negreta", "Arial Normal", "Arial";
    font-weight: 700;
    font-size: 17px;
    color: #ff6600;
  }
}
.Invoicingbackground {
  // padding: 0px 10px;
}
.margb {
  margin-bottom: 20px;
}

/deep/ .el-input__suffix-inner {
  // line-height: 50px;
  // display: none;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__item {
  padding: 0;
  width: 75px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

/deep/ .el-tabs__active-bar {
  display: none;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.inputBor {
  display: flex;
  border: 1px solid #f2f2f2;

  /deep/.el-input__inner {
    border: 0 !important;
  }

  span {
    line-height: 30px;
  }
}

/deep/ .el-input__icon {
  line-height: 22px;
}

/deep/ .el-tabs__item.is-active {
  color: #fff;

  @include themify($themes) {
    background-color: themed("themes_color");
  }
}

/deep/ .el-input {
  height: 32px;
  line-height: 32px;
}

/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}

/deep/ .el-date-editor .el-range__icon {
  height: 32px;
}

/deep/ .el-date-editor .el-range-separator {
  height: 32px;
}

/deep/ .el-input__suffix-inner {
  // line-height: 50px;
}
.tabone-num {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: #6c40bf;
  text-align: center;
}

.tabone-con {
  font-size: 14px;
  color: #6c40bf;
  border-bottom: none;
}

.tabone-num1 {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: #333333;
}

.tabone-con1 {
  font-size: 14px;
  color: #333333;
  text-align: center;
  border-bottom: none;
}

.date {
  position: relative;
  top: 2px;
  left: 285px;
}

.myPointsContent {
  width: 917px;
  min-height: 485px;
  background-color: #ffffff;
  // padding:0px 13px;
}

.allOrder-btn {
  display: flex;
  // position: relative;
  // left: 295px;
}

.allOrder-btn div:nth-child(1) {
  width: 75px;
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  line-height: 32px;
}

.allOrder-btn div:nth-child(2) {
  width: 110px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  margin-right: 10px;
}

.allOrder-btn div:nth-child(3) {
  width: 110px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  margin-right: 10px;
}

.allOrder-btn div:nth-child(4) {
  width: 110px;
  height: 32px;
  text-align: center;
  line-height: 32px;
}

.block {
  margin-left: 260px;
  margin-right: 10px;
}

::v-deep .el-table__row {
  height: 38px;
}

.red {
  color: #ff0000;
}

.black {
  color: #000;
}

/* tab切换标题 */
.tabTitleRow {
  height: 40px;
  background-color: #f8f9fe;
  border: 1px solid #eee;
  margin-top: 17px;
  box-sizing: border-box;
  margin-bottom: 17px;
}

::v-deep .el-table .cell {
  white-space: nowrap;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

::v-deep .el-table thead {
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  text-align: center;
}

::v-deep .el-table th {
  height: 38px;
  padding: 0px 0px;
  text-align: center;
  color: #666666;
  font-weight: normal;
}
::v-deep .el-table td,
::v-deep .el-table th {
  height: 38px;
  padding: 0px 0px;
  color: #666666;
  font-weight: normal;
  font-size: 12px;
}

::v-deep .el-table th {
  text-align: center;
}
::v-deep .is-leaf {
  border-right: 0px solid transparent;
  background-color: #f2f2f2;
}

.tabTitleRow div {
  width: 108px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: #666;
  text-align: center;
  box-sizing: border-box;
}

.tabTitleRow div:first-child {
  border-left: none !important;
}

.tabTitleRow .checkTitleSty {
  @include themify($themes) {
    color: themed("themes_color");
  }

  font-weight: bold;
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.line-height39 {
  line-height: 39px;
}

.billtext {
  line-height: 36px;
}

.color-theme {
  @include themify($themes) {
    color: themed("themes_color");
  }
}

.background-color-theme {
  @include themify($themes) {
    background-color: themed("themes_color");
    color: themed("text_color_white");
  }
}

.aaa {
  width: 800px;
  justify-content: space-evenly;
  margin-top: 20px;
  border-bottom: none;
}

.tabOne {
  width: 100%;
  height: 135px;
  background: #f8f5fc;
  border-radius: 2px;
  border: 1px solid #6c40bf;
  margin-top: 10px;
  position: relative;
}

.tabOne-Title {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  color: #6c40bf;
  margin: 10px 0px 10px 10px;
}

.tabOne img {
  width: 180px;
  height: 119px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.topbgc {
  // width: 917px;
  height: 35px;
  background: #f8f9fe;
  padding: 0 15px;
  margin: 11px auto;
}

::v-deep.el-input--suffix .el-input__inner {
  line-height: 32px !important;
  height: 32px !important;
}

::v-deep .el-input__icon {
  line-height: 32px !important;
}

.billsitems {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #f2f2f2;
}

.allOrder-btn .zad {
  /* position: absolute;
		left: 1095px; */
}

.allOrder-btn .ad {
  /* position: absolute;
		left: 1175px; */
}
/deep/ .el-input {
  width: 185px !important;
}
/deep/ .el-pagination {
  padding: 3px 5px;
}
/* 步骤条 */
.yijia-step {
  height: 111px;
  border: 1px solid #f2f4fd;
  box-sizing: border-box;
}
::v-deep .is-success {
  border-color: #dffff5;
  color: #00cc88;

  .el-step__icon.is-text {
    background: #00cc88;
    color: #dffff5;
  }
}

::v-deep .el-step__title.is-success {
  font-weight: 400;
  font-size: 14px;
  // color: #999999;
}

::v-deep .el-step__line-inner {
  height: 10px;
  background-color: #00cc88;
}

::v-deep .el-step__icon.is-text {
  background: #00cc88;
  color: #fff;
  border: 5px solid;
}

::v-deep .el-step__icon {
  height: 50px;
  width: 50px;
}

::v-deep .el-step.is-horizontal .el-step__line {
  height: 10px;
  background: #e4e4e4;
  margin-top: 8px;
}

::v-deep .is-success {
  border-color: #dffff5;
  color: #00cc88;

  .el-step__icon.is-text {
    background: #00cc88;
    color: #dffff5;
  }
}

::v-deep .is-process {
  border-color: #fafafa;
  color: #999;

  .el-step__icon.is-text {
    background: #e4e4e4;
    color: #fafafa;
  }
}

::v-deep .is-wait {
  border-color: #fafafa;
  color: #999;

  .el-step__icon.is-text {
    background: #e4e4e4;
    color: #fafafa;
  }
}

::v-deep .el-step__title.is-process {
  font-weight: 400;
  font-size: 14px;
  // color: #999999;
}

::v-deep .el-step__title.is-success {
  font-weight: 400;
  font-size: 14px;
  // color: #999999;
}

::v-deep .el-step__title.is-wait {
  font-weight: 400;
  // color: #999999;
}

/* 步骤条结束 */
.search-box {
  width: 360px;
  /deep/ .el-input__inner {
    height: 30px;
    width: 330px;
    line-height: 30px;
    border: 1px solid #f2f2f2;
  }
}

.search-btn {
  width: 79px;
  height: 32px;
  line-height: 32px;
  background: #f8f7fa;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background: inherit;
  background-color: rgba(248, 247, 250, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(228, 228, 228, 1);
  border-radius: 4px;
}
</style>
